<template>
    <div class="d-flex justify-content-center ">
        <div class="bg-white p-4" v-if="checkUser">
            <div v-if="!done">
                <h3 class="bold">Đăng ký nhận thông báo qua Telegram</h3>
                <p class="mt-4">Bạn có chắc chắn muốn đăng ký nhận thông báo qua telegram từ BOT CSVC</p>
                <div class="d-flex justify-content-center mt-4">
                    <el-button @click="action(false)">Từ chối</el-button>
                    <el-button class="bg-violet text-white" @click="action(true)">Xác nhận</el-button>
                </div>
            </div>
            <div v-else class="text-center">
                <img :src="publicPath + 'media/svg/Vector_11.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
                     aria-roledescription="logo">
                <h3 class="bold mt-4">Đăng ký nhận thông báo telegram thành công</h3>
                <div class="mt-4">
                    <a class="bg-violet p-2 rounded text-white " href="https://t.me/edutalk_infrastructure_bot">Truy cập bot</a>
                </div>
            </div>
        </div>
        <div v-else>
            <h2 class="bold">Tài khoản và mã nhân sự không khớp. Vui lòng kiểm tra lại</h2>
        </div>
    </div>
</template>

<script>
import {CHECK_USER_VERIFILE, VERIFILE_TELEGRAM_USER} from "../../core/services/store/user/users.module";

export default {
    name: "VerifileTelegramUser",
    data() {
        return {
            checkUser: false,
            done: false,
            publicPath: process.env.VUE_APP_BASE_URL
        }
    },
    mounted() {
        this.checkUserVerifile();
    },
    methods: {
        action(value) {
            this.$store.dispatch(VERIFILE_TELEGRAM_USER, {status: value})
                .then((response) => {
                    this.$notify({
                        title: 'Thành công',
                        message: 'Gửi yêu cầu thành công',
                        type: 'success'
                    });
                });
            this.done = value;

            if (value == false) {
                this.$router.push(
                    {
                        name: "dashboard",
                    }
                )
            }
        },
        checkUserVerifile() {
            this.$store.dispatch(CHECK_USER_VERIFILE, {id: this.$route.query.id})
                .then((response) => {
                    if (response.data) {
                        this.checkUser = true;
                        return;
                    }
                    this.checkUser = false;
                })
        }
    }
}
</script>

<style scoped>
 .bg-violet {
     background: blueviolet;
 }
</style>